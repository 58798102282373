import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";

// Assets

const CareersJobPosting = () => (
  <Layout currentPage="careers-listing">
    <div className="container">
      <Link to="/careers#alljobs">
        <p className="backbutton col-md-12">Back to Careers</p>
      </Link>
      <h1 className="jobtitle col-md-12">Electrical Engineer</h1>
      <div className="jobdescription col-md-12" />
      <div className="row col-md-12 justify-content-md-between">
        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You will:
          </div>
        </div>
        <div className="col-md-9">
          <ul className="aligned-left jobsection">
            <li>
              Design, prototype, bring-up, debug, characterize and implement
              Uno’s electrical components.{" "}
            </li>
            <li>
              Work with suppliers, contractors and senior design engineers to
              ensure that the product is developed on time and on budget.{" "}
            </li>
            <li>
              Lead design maturity for mass production through failure analysis
              resolution and performance optimization iteration. Justify design
              changes through quantitative analysis of trade‐offs.{" "}
            </li>
            <li>
              Participate in cross functional design reviews, and address
              integration concerns{" "}
            </li>
            <li>Identify and research technologies for new products.</li>
            <li>
              Establish performance metrics and requirements through the use of
              models and simulation, and design of versatile comprehensive
              validation systems for prototype bring‐up and validation of models
            </li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">You are:</div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>
              An expert in electrical engineering—in theory and in practice.
            </li>
            <li>Diligent with action item and bug tracking.</li>
            <li>Methodical in your design and problem-solving approach.</li>
            <li>A strong troubleshooter with developed analytical skills </li>
            <li>Committed to high quality, high performing designs. </li>
            <li>
              Quick at identifying when you’ve gone down a wrong path and change
              direction.
            </li>
            <li>
              Able to author well-considered engineering requirements and
              specifications{" "}
            </li>
            <li>
              Familiar with parts, components and equipment—as well as where to
              source them.
            </li>
            <li>
              Knowledgeable in pre-made solutions and how to implement them.{" "}
            </li>
            <li>
              Collaborative and understand how to integrate your work with
              others.
            </li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You bring:
          </div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>BS/MS/Ph.D in Electrical Engineering or related field.</li>
            <li>3+ years working on electrical products</li>
            <li>Craft in applicable schematic design.</li>
            <li>
              Ability to build proof-of-concept and initial prototype hardware.
            </li>
            <li>Knowledge in PCB layout.</li>
            <li>
              Experience with bring-up, debug, and validation of digital
              designs.
            </li>
            <li>
              Hands on electrical engineering proficiency (specifically in
              analog circuit design and validation).{" "}
            </li>
            <li>
              Vast experience with electrical design tools (Altium, KiCad, etc.)
            </li>
            <li>
              Lab experience including data collection and lab test equipment
              such as: oscilloscopes, spectrum analyzers, power supplies, etc.
            </li>
            <li>
              Experience in developing and manufacturing high volume products is
              a plus.
            </li>
            <li>Experience working on consumer tech products is a plus.</li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You apply:
          </div>
        </div>
        <div className="col-md-9">
          <div className="aligned-left" style={{ paddingBottom: "100px" }}>
            By sending us an <a href="mailto:careers@introducing.uno">email</a>{" "}
            with your resume and a cover letter.
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default CareersJobPosting;
